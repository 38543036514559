import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
    isEditing: types.optional(types.boolean, false),
}).actions((self) => {
    
    const toggleIsEditing = () => {
        self.isEditing = !self.isEditing
    }
    
    const offIsEditing = () => {
        self.isEditing = false
    }


    return {
        toggleIsEditing,
        offIsEditing,
    }
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
