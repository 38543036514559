import React from 'react';
import './App.css';
import firebase from "firebase/app";
import { RootNavigator } from './navigation';
import { initFirebase } from './helpers';
import { RootStore, RootStoreProvider, setupRootStore } from './models';

function App() {
  const [initStatus, setInitStatus] = React.useState(false)
  const [rootStore, setRootStore] = React.useState<RootStore | undefined>(undefined)

  const isFirebaseInit = () => {
    // Initialize Firebase
    return firebase.apps && firebase.apps.length > 0;
  }

  React.useEffect(() => {
    if (!isFirebaseInit() && !initStatus) {
      initFirebase();
    }
    setInitStatus(true)
  }, [initStatus])

  React.useEffect(() => {
    ; (async () => {
      setupRootStore().then(setRootStore)
    })()
  }, [])

  if (!isFirebaseInit() || !rootStore) return null


  return (
    <RootStoreProvider value={rootStore}>
      <RootNavigator />
    </RootStoreProvider>
  );
}

export default App;
