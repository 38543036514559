import React from "react";
import {
    HomePage,
    ArtFlowPage,
} from "../screens";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    // NavLink,
} from "react-router-dom";
// import { checkUserAuth, logout, callFunction } from "../helpers/firebase-helper";
import { observer } from "mobx-react-lite"

import "./root-navigator.css"
import { useStores } from "../models";

export const RootNavigator = observer(function RootNavigator() {
    // const [isNavOpen, setIsNavOpen] = React.useState(false)
    // const [navItems] = React.useState([
    //     { to: "/", title: "Home" },
    // ])
    const isEditing = useStores().isEditing
    // const toggleEditing = useStores().toggleIsEditing
    const offIsEditing = useStores().offIsEditing

    React.useEffect(() => {
        if (isEditing) {
            offIsEditing()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
            <Router>
                <div className="window-container">
                    {/* <div className={`sidebar-container ${isNavOpen ? "open" : ""}`}>
                        <div className="sidebar-off-toggle" onClick={() => setIsNavOpen(d => !d)}>
                            <svg width="20px" height="20px" role="presentation" viewBox="0 0 16 14">
                                <path d="M15 0L1 14m14 0L1 0" stroke="#FFFFFF" fill="none" fill-rule="evenodd"></path>
                            </svg>
                        </div>
                        {navItems.map((n) => (
                            <div className="mob-nav-item" key={n.to}>
                                <NavLink activeClassName="active-nav-item" exact to={n.to}>{n.title}</NavLink>
                            </div>
                        ))}
                    </div>
                    <nav className="nav-container">
                        <div className="sidebar-toggle" onClick={() => setIsNavOpen(d => !d)}>
                            <svg width="20px" height="20px" role="presentation" viewBox="0 0 20 14">
                            <path d="M0 14v-1h20v1H0zm0-7.5h20v1H0v-1zM0 0h20v1H0V0z" fill="#0094F9"></path>
                            </svg>
                        </div>
                        <div className="mob-nav-logo">
                            <img height="40px" src="https://humballah.com/email-signature/logo.png" alt="logo" />
                        </div>
                        <div className="nav-item desktop-item">
                            <img height="50px" src="https://humballah.com/email-signature/logo.png" alt="logo" />
                        </div>
                        {navItems.map((n) => (
                            <div className="nav-item desktop-item" key={n.to}>
                                <NavLink activeClassName="active-nav-item" exact to={n.to}>{n.title}</NavLink>
                            </div>
                        ))}
                        <div className="nav-spacer-item" />
                        <div className="nav-margin-item" />
                    </nav> */}

                    {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                    <div className="page-container">
                        <Switch>
                            <Route path="/" exact>
                                <HomePage />
                            </Route>
                            <Route path="/artflow">
                                <ArtFlowPage />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Router>
    );
})
