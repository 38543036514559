import firebase from "firebase/app";
import "firebase/functions";
import "firebase/database";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyABICy9npF6UHRjIp8g9B5c0wQgZBy4N9k",
    authDomain: "horti-groot.firebaseapp.com",
    projectId: "horti-groot",
    storageBucket: "horti-groot.appspot.com",
    messagingSenderId: "550164199746",
    appId: "1:550164199746:web:071e3c7803254dfbaf30db"
};

export const initFirebase = () => {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
}

export const callFunction = (name: string, data: any, options?: firebase.functions.HttpsCallableOptions) => {
    // firebase.functions().useEmulator("localhost", 5001);
    // const functionObject = firebase.functions().httpsCallable(name, options);
    const functionObject = firebase.app().functions('us-central1').httpsCallable(name, options);
    return functionObject(data);
}

export const listenToPath = (name: string, callback: (value: any) => void) => {
    const database = firebase.database();
    return database.ref(name).on("value", (snap: any) => {
        const val = snap.val()
        callback(val)
    })
}

export const stopListenToPath = (name: string, callback: (value: any) => void) => {
    const database = firebase.database();
    return database.ref(name).off("value", (snap: any) => {
        const val = snap.val()
        callback(val)
    })
}

export const checkUserAuth = (callback: (user: firebase.User | null) => void) => {
    const auth = firebase.auth();
    return auth.onAuthStateChanged((user: any) => {
        if (callback) {
            callback(user)
        }
    })
}

export const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();

    provider.setCustomParameters({
        'login_hint': 'user@heyhorti.com'
    });

    const auth = firebase.auth();
    return auth.signInWithPopup(provider)
        .then((result: any) => {
            const user = result.user;
            return user
        }).catch((error: any) => {
            console.log("Log In Error", error)
        });
}

export const logout = () => {
    const auth = firebase.auth();

    return auth.signOut()
}
