import React from 'react';
// import { Screen } from '../../components'
import './styles.css'
import { ReactComponent as EngLogo } from './English.svg'
import { ReactComponent as ArLogo } from './Arabic.svg'
import Background from './bg.png'
import BackgroundPhone from './bg-phone.png'
import Logo from './logo.png'
import AboutUs from './aboutUs.png'
import OurServices from './services.png'
// import FBIcon from './fbicon.png'
import InstaIcon from './instaicon.png'

export function HomePage() {
    const [isOpeningShop, setIsOpeningShop] = React.useState(false)
    const openShop = () => {
        setIsOpeningShop(true)
        setTimeout(() => {
            window.location.assign("https://shop.humballah.com")
        }, 400)
    }

    const openContact = () => {
        setIsOpeningShop(true)
        setTimeout(() => {
            window.location.assign("https://shop.humballah.com/pages/contact-us")
        }, 400)
    }

    const openBooking = () => {
        setIsOpeningShop(true)
        setTimeout(() => {
            window.location.assign("https://calendly.com/humballah/consultation")
        }, 400)
    }

    const goToInsta = () => {
        window.location.assign("https://www.instagram.com/humballah")
    }

    // const goToFacebook = () => {
    //     window.location.assign("https://www.facebook.com/humballah")
    // }

    React.useEffect(() => {
        function handleResize() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        handleResize()
        window.addEventListener('resize', handleResize)
    })

    return (
        // <Screen isLoading={false}>
            <div className="home-container">
                <img src={window.innerWidth > 767 ? Background : BackgroundPhone} className="body-bg" alt="Humballah Logo" />
                {/* <div className="home-slideshow-container">

                </div> */}
                <div className="header-wrapper">
                    <div className="header-container">
                        <img src={Logo} className="header-logo-img" alt="Humballah Logo" />
                        <ArLogo className="header-logo" />
                        <EngLogo className="header-logo-2" />
                    </div>
                    <div className="home-nav-container">
                        <div className="home-nav-item" onClick={() => openShop()}>
                            {"Shop"}
                        </div>
                        <div className="home-nav-item" onClick={() => openBooking()}>
                            {"Book"}
                        </div>
                        <div className="home-nav-item" onClick={() => openContact()}>
                            {"Contact"}
                        </div>
                        <div className="home-nav-item home-nav-circle" onClick={() => goToInsta()}>
                        <img src={InstaIcon} className="home-insta-link" alt="instagram Logo" />
                        </div>
                    </div>
                </div>
                <div className="home-scroll-container">
                    <div className="home-card-item home-card-about">
                        <div className="home-card-about-body">
                            <div className="home-card-about-text">
                                <div className="home-card-title">{"About Us"}</div>
                                <div className="home-card-about-text-body">
                                <span>Humballah was founded in 2019 by Ali Ali & Sarah Ashmawy. Their vision was to create a place of playful expression, where many diciplines can come together to co-create!</span><br />
                                <span className="desktop-only">Since then, Humballah has grown into a<br /> multi-diciplinary team of designers and engineers who work together to create<br /> human-centric experiences online & offline.</span>
                                <span className="mobile-only">Since then, Humballah has grown into a multi-diciplinary team of designers and engineers who work together to create human-centric experiences online & offline.</span>
                                </div>
                            </div>
                            <div className="home-card-about-image">
                                <img src={AboutUs} alt="about us" />
                            </div>
                        </div>
                    </div>
                    <div className="home-card-item home-card-services">
                        <div className="home-card-services-body">
                            <div className="home-card-services-text">
                                <div className="home-card-title">{"Our Services"}</div>
                                <div className="home-card-services-text-body">
                                    <div className="home-card-services-item">
                                        <div className="home-card-services-item-title">
                                            {"UX/UI Design"}
                                        </div>
                                        <div className="home-card-services-item-description">
                                            {"We create design packages for both Mobile Apps & Web Apps."}
                                        </div>
                                    </div>
                                    <div className="home-card-services-item">
                                        <div className="home-card-services-item-title">
                                            {"Software Development"}
                                        </div>
                                        <div className="home-card-services-item-description">
                                            {"We offer Full-stack development services, turning ideas into working Web Apps or Mobile Apps."}
                                        </div>
                                    </div>
                                    <div className="home-card-services-item">
                                        <div className="home-card-services-item-title">
                                            {"Project Management Consultations"}
                                        </div>
                                        <div className="home-card-services-item-description">
                                            {"We consult companies on how to create an effective & happy team."}
                                        </div>
                                    </div>
                                    <div className="home-card-services-item">
                                        <div className="home-card-services-item-action-title">
                                            {"Contact us to know more about our packages and rates."}
                                        </div>
                                        {/* <div className="home-card-services-item-action">
                                            <div className="home-nav-item" onClick={() => openBooking()}>
                                                {"Book"}
                                            </div>
                                            <div className="home-nav-item" onClick={() => openContact()}>
                                                {"Contact"}
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="home-card-services-image">
                                <img src={OurServices} alt="our services" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="home-card-item home-card-projects">
                        <div className="home-card-projects-body">
                            <div className="home-card-projects-text">
                                <div className="home-card-title">{"Our Projects"}</div>
                                <div className="home-card-projects-text-body">
                                    <span>Humballah was founded in 2019 by Ali Ali & Sarah Ashmawy. Their vision was to create a place of playful expression, where many diciplines can come together to co-create!</span><br />
                                    <span className="desktop-only">Since then, Humballah has grown into a<br /> multi-diciplinary team of designers and engineers who work together to create<br /> human-centric experiences online & offline.</span>
                                    <span className="mobile-only">Since then, Humballah has grown into a multi-diciplinary team of designers and engineers who work together to create human-centric experiences online & offline.</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="home-card-item">
                        <div className="home-card-title">{"About Us"}</div>

                    </div> */}
                </div>
                <div className={`shop-placeholder ${isOpeningShop ? "shop-placeholder-open" : ""}`}>
                </div>
            </div>
        // </Screen>
    )
}