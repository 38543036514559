import React from 'react';
// import { Screen } from '../../components'
import './styles.css'

const rowItems = 5
const columnItems = Math.floor((594 / 420) * rowItems)

export function ArtFlowPage() {

    const [selectedImages, setSelectedImages] = React.useState<any[]>([])

    const onChangeImages = (e: any) => {
        var files = e.target.files;
        console.log(files);
        var filesArr = Array.prototype.slice.call(files);
        console.log(filesArr);
        setSelectedImages(d => {
            return [...d, ...filesArr]
        })
    }

    const removeFile = (f: any) => {
        setSelectedImages(d => {
            return d.filter(x => x !== f)
        })
    }

    const [canvasImages, setCanvasImages] = React.useState<any[]>([])

    const randomizeCanvas = () => {
        console.log("YOO")
        const result = []
        if (selectedImages.length > 0) {
            const requiredImages = rowItems * columnItems
            const imageDimensions = (window.innerWidth * 0.3) / rowItems
            const rotations = [0, 90, 180, 270]
            for (let i = 0; i < requiredImages; i += 1) {
                const selectRandomImage = Math.floor(Math.random() * selectedImages.length)
                const selectRandomRotation = rotations[Math.floor(Math.random() * rotations.length)]
                const selectedImage = selectedImages[selectRandomImage]
                result.push(
                    <img
                        alt={selectedImage.name}
                        key={i}
                        src={URL.createObjectURL(selectedImage)}
                        style={{
                            width: imageDimensions,
                            height: imageDimensions,
                            objectFit: "cover",
                            transform: `rotate(${selectRandomRotation}deg)`
                        }} />
                )
            }
        }
        setCanvasImages(result)
    }
    
    React.useEffect(() => {
        function handleResize() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            randomizeCanvas()
        }

        handleResize()
        window.addEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        randomizeCanvas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedImages])

    return (
        <div className="main-container">
            <p>Welcome to Art Flow</p>

            <div className="columns-container">
                <div className="selector-column">
                    <label className="custom-file-upload">
                        <input type="file" multiple onChange={onChangeImages} />
                        Add images
                    </label>
                    <div className="selected-images">
                        {selectedImages.map(x =>
                            <div className="selected-image">
                                <div className="delete-image" onClick={() => removeFile(x)}>X</div>
                                <img alt={`${x.name}`} className="preview-image-thumb" src={URL.createObjectURL(x)} />
                                {/* <div className="file-preview-filler"></div> */}
                            </div>
                        )}
                    </div>
                </div>
                <div className="canvas-column">
                    <div className="main-canvas">
                        {canvasImages}
                    </div>
                </div>
            </div>

        </div>
    )
}